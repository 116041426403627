import React from 'react';
import { Container } from './styles';
import FileViewer from 'react-file-viewer';
import { Button } from '@mui/material';

const onError = (e) => {
  console.log(e, 'error in file-viewer');
};

interface IProps {
  fileType: string;
  filePath: string;
}

function VisualizadorArquivo({ fileType, filePath }: IProps): React.ReactElement {
  return (
    <Container>
      <div
        style={{
          border: '1px solid #cccccc',
          height: '100%',
          borderRadius: '5px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button href={filePath} target="_blank" rel="noreferrer" color="success">
            Download Arquivo
          </Button>
        </div>
        <div
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            height: '400px',
          }}
        >
          <FileViewer fileType={fileType} filePath={filePath} onError={onError} />
        </div>
      </div>
    </Container>
  );
}

export default VisualizadorArquivo;
