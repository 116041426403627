import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { ArrowBackIos } from '@material-ui/icons';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ModalOptions from '../../../../components/ModalOptions';
import { convertTextToPDF, copyText, downLoadText } from '../../../../helper/convertHTML';
import {
  createEmenta,
  getFileEmentaUrl,
  getModelById,
  updateModel,
  upload,
  verifySummary,
} from '../../../../services/models';
import { Buttons, Container, ContainerView, Content, TopContent, useStyles } from './styles';
import { useLoading } from '../../../../contexts/loading';
import { useAlert } from '../../../../contexts/alert';
import { useAuth } from '../../../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';
import IAConfiguration from './ConfigurationContainer';
import IAView from './AnswerView';
import Tooltip from '@mui/material/Tooltip';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useHistory } from 'react-router-dom';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import md5 from 'crypto-md5';
import { createImageFile } from '../../../../helper/convertHTML';

const IAEmentaModel: React.FC = () => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const { user } = useAuth();
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const classes = useStyles();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [controlCancelEditingModal, setControlCancelEditingModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);
  const [temporaryPathName, setTemporaryPathName] = useState<string>('');
  const [temporarySuffix, setTemporarySuffix] = useState<string>('');
  const [xChangedTexto, setXchangedTexto] = useState('');
  const [md5Hash, setMd5Hash] = useState('');
  const history = useHistory();
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const inputRef = useRef(null);
  const [editMode, setEditMode] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: '',
      descricao: '',
      titulo: '',
      metaFile: '',
      html: '',
      json: '',
      tipo: 0,
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: '',
    },
    onSubmit: () => {},
  });

  const onSave = async () => {
    let errorMetaFile = md5Hash.length > 0 ? '' : 'Não há nenhum texto carregado.';
    let errorDescricao =
      formik.values.descricao.length > 3
        ? ''
        : 'O preenchimento da descrição do projeto com no mínimo 3 caracteres é requerido.';
    if (errorDescricao.length > 0 || errorMetaFile.length > 0) {
      showAlertError(
        'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente. ' +
          errorMetaFile +
          ' ' +
          errorDescricao
      );
      return;
    }

    showLoading(true);

    if (!idModel) {
      setEditMode(false);
      formik.values.id = uuidv4();
      formik.values.metaFile = md5Hash;
      formik.values.html = document.getElementById('ContainerViewEmenta').innerHTML;
      formik.values.json = JSON.stringify(xChangedTexto);
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'));
      createEmenta(formik.values)
        .then(() => {
          setIsSaved(true);
          showLoading(false);
          showAlertSuccess('Texto gravado com sucesso.');
          setIdModel(formik.values.id);
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else if (error.status === 412) {
            showAlertError('Atingido o limite de modelos do plano.');
          } else {
            showAlertError('Ocorreu um erro ao gravar o texto.');
          }
        });
    } else {
      setEditMode(false);
      formik.values.metaFile = md5Hash;
      formik.values.html = document.getElementById('ContainerViewEmenta').innerHTML;
      formik.values.json = JSON.stringify(xChangedTexto);
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'));
      const response = await updateModel('ModeloEmenta', formik.values);
      if (response.status !== undefined) {
        if (response.status === 204) {
          showLoading(false);
          setIsSaved(true);
          showAlertSuccess('Modelo atualizado com sucesso.');
          setIdModel(idModel);
        } else {
          showLoading(false);
          if (response.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao gravar o texto.');
          }
        }
      } else {
        showLoading(false);
        if (response.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError('Ocorreu um erro ao gravar o texto.');
        }
      }
    }
  };

  const getEmentabyId = async (modeloId: string) => {
    showLoading(true);
    setEditMode(true);
    const response = await getModelById('ModeloEmenta', clientTitularId, modeloId);

    if (response.status === 200) {
      let dataFormik = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        titulo: response.data[0].titulo,
        html: response.data[0].html,
        json: response.data[0].json.replaceAll('"', ''),
        tipo: response.data[0].tipo,
        metaFile: response.data[0].metafile,
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      };
      formik.setValues(dataFormik);
      let fileName = await getFileEmentaUrl(dataFormik.clienteTitularId, dataFormik.id);
      setTemporaryPathName(fileName.data);
      if (fileName.data.indexOf('.PDF') > 0) {
        setTemporarySuffix('pdf');
      } else {
        setTemporarySuffix('docx');
      }
      setXchangedTexto(dataFormik.json);
      setIsSaved(true);
      showLoading(false);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  useEffect(() => {
    if (idModel) {
      getEmentabyId(idModel);
    }
  }, [idModel]);

  const onCancelEditing = () => history.push('/novo-modelo');

  const fileUpload = () => {
    inputRef.current.click();
  };

  const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const urlValue = URL.createObjectURL(inputRef.current.files[0]);
    let fileName = inputRef.current.files[0].name.toUpperCase();
    let suffix =
      fileName.substring(fileName.length - 1) == 'X'
        ? fileName.toLowerCase().substring(fileName.length - 4)
        : fileName.toLowerCase().substring(fileName.length - 3);
    setTemporaryPathName(urlValue);
    setTemporarySuffix(suffix == 'doc' ? 'docx' : suffix);
    showLoading(true);
    let md = await getHash(event.target.files[0]);
    setMd5Hash(md);
    let response = await verifySummary(md);
    if (response.data == '') {
      response = await upload(event.target.files[0], formik.values.tipo, md);
    }
    setXchangedTexto(response.data);
    showLoading(false);
    event.target.value = null;
  };

  const getHash = (file: File) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (ev) {
        const buffer = Buffer.from(new Uint8Array(ev.target?.result));
        resolve(md5(buffer, 'hex').toString());
      };
      reader.onerror = reject; // call reject if error
    });
  };

  const cleanFile = () => {
    setIsSaved(false);
    formik.values.transformedText = '';
    setXchangedTexto('');
    setTemporaryPathName('');
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    downLoadText(xChangedTexto, formik.values.descricao);
  };

  const downloadPDF = () => {
    convertTextToPDF(xChangedTexto, formik.values.descricao);
  };

  const copy = () => {
    copyText(xChangedTexto);
    setOpenToolTipCopyImage(true);
  };

  return (
    <Container elevation={1}>
      <ModalOptions
        text={`As alterações não foram salvas, deseja continuar?`}
        open={Boolean(controlCancelEditingModal)}
        onCancel={() => setControlCancelEditingModal(false)}
        onSave={() => {
          history.block(() => {});
          history.push(temporaryPathName);
        }}
      />
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <TopContent>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => history.push('/meus-modelos')}>
                <ArrowBackIos />
              </a>
              <p>
                Modelo / <span className="color-text">IA - Ementa</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo Ementa"
              className="descricao"
              variant="outlined"
              type="text"
              id="descricao"
              name="descricao"
              fullWidth
              value={formik.values.descricao}
              defaultValue={formik.values.descricao}
              onChange={(event) => {
                setIsSaved(false);
                formik.handleChange(event);
              }}
              error={formik.touched.descricao && Boolean(formik.errors.descricao)}
              helperText={formik.touched.descricao && formik.errors.descricao}
            />
            <FormControl className="tipoResumo" variant="outlined" style={{ minWidth: '180px' }}>
              <InputLabel id="idTipo">Tipo do Resumo</InputLabel>
              <Select
                labelId="tipo"
                id="tipo"
                value={formik.values.tipo}
                onChange={(e) => formik.setFieldValue('tipo', e.target.value)}
                label="Tipo do Resumo"
                disabled={editMode ? true : false}
              >
                <MenuItem value={0}>Texto</MenuItem>
                <MenuItem value={1}>Tópicos</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Modelo"
              className="modelo"
              variant="outlined"
              value={'IA - Ementa'}
              disabled
              onChange={() => {}}
            />
          </div>
        </Content>
        <Container>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '49%', maxWidth: '49%', display: 'flex', justifyContent: 'right' }}>
              <Tooltip title="Carregar arquivo">
                <CloudUploadOutlinedIcon onClick={fileUpload} style={{ color: '#3E4756', visibility: 'hidden' }} />
              </Tooltip>
              <input
                hidden
                id="fileName"
                ref={inputRef}
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={onFileInputChange}
                webkitdirectory
              />
              <Tooltip title="Apagar arquivo carregado">
                <DeleteOutlineOutlinedIcon
                  style={{ color: '#F1504D', visibility: editMode ? 'hidden' : 'visible' }}
                  onClick={cleanFile}
                />
              </Tooltip>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Download">
                <span
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
                  }
                  style={{ cursor: 'pointer', color: '#3E4756' }}
                  className="material-icons-outlined"
                >
                  file_download
                </span>
              </Tooltip>
              <Tooltip
                title="Texto copiado"
                open={openToolTipCopyImage}
                onClose={() => setOpenToolTipCopyImage(false)}
                leaveDelay={1500}
              >
                <Tooltip title="Copiar texto">
                  <span
                    onClick={() =>
                      setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                    }
                    style={{ cursor: 'pointer', color: '#3E4756' }}
                    className="material-icons-outlined"
                  >
                    <ContentCopyOutlinedIcon style={{ color: '#3E4756' }} />
                  </span>
                </Tooltip>
              </Tooltip>
              <Tooltip title="Download PDF">
                <LocalPrintshopOutlinedIcon
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
                  }
                  style={{ color: '#3E4756' }}
                />
              </Tooltip>
            </div>
          </div>
          <ContainerView id="ContainerViewEmenta">
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignmentBaseline: 'central',
                gap: '20px',
              }}
            >
              <IAConfiguration
                origin={0}
                fileType={temporarySuffix}
                filePath={temporaryPathName}
                uploadFunction={fileUpload}
              />
              <IAView setText={setXchangedTexto} text={xChangedTexto} />
            </div>
          </ContainerView>
        </Container>
      </form>
      <Buttons>
        <Button className={classes.buttonRed} variant="outlined" onClick={onCancelEditing}>
          Cancelar
        </Button>
        <Button disabled={editMode} onClick={onSave} className={classes.buttonGreen} variant="contained">
          Salvar
        </Button>
      </Buttons>
    </Container>
  );
};

export default IAEmentaModel;
