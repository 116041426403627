import React from 'react';
import ConfigurationCard from '../../ConfigurationCard';
import { CardsContainer, Container, Insert } from './styles';
import TextField from '../../../../../components/EditTableTemplateTextField';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { DATETYPEINPUT } from '../../../../../interfaces/timeline';
import { Tooltip } from '@material-ui/core';
import AddOutlineIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlineIcon from '@mui/icons-material/RemoveOutlined';
import ReorderIcon from '@mui/icons-material/Reorder';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
  color: string;
}

interface IProps {
  data: Array<IItem>;
  title: string;
  addNewItem: () => void;
  increaseWidth: () => void;
  decreaseWidth: () => void;
  reorderItens: () => void;
  dateType: DATETYPEINPUT;
  changeItemValue: (index: number, value: string, name: string) => void;
  controlDeleteModal: (index: number) => void;
  controlDeleteAllModal: () => void;
  controlModalColor: (index: number, value: string) => void;
  changeTitleTimeline: (value: string) => void;
}

const VerticalConfiguration = ({
  data,
  title,
  dateType,
  addNewItem,
  increaseWidth,
  decreaseWidth,
  reorderItens,
  changeItemValue,
  controlDeleteModal,
  controlDeleteAllModal,
  controlModalColor,
  changeTitleTimeline,
}: IProps) => {
  return (
    <Container>
      <div className="info-title" style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 10, color: '#3E4756', width: '500px', paddingLeft: '20px' }}>
          Informe os dados do modelo
        </p>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title="Criar linha do tempo com IA a partir do texto da prancheta">
            <AutoFixHigh style={{ cursor: 'pointer', color: '#3E4756' }} onClick={controlDeleteAllModal} />
          </Tooltip>
          <Tooltip title="Reordenar todos os elementos">
            <ReorderIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={reorderItens} />
          </Tooltip>
          <Tooltip title="Aumentar a largura de todos os elementos">
            <AddOutlineIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={increaseWidth} />
          </Tooltip>
          <Tooltip title="Diminuir a largura de todos os elementos">
            <RemoveOutlineIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={decreaseWidth} />
          </Tooltip>
          <Tooltip title="Alterar cor de todos os elementos">
            <ColorLensOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() => controlModalColor('all')}
            />
          </Tooltip>
          <Tooltip title="Configurações">
            <SettingsOutlinedIcon style={{ cursor: 'pointer', display: 'none' }} />
          </Tooltip>
        </div>
      </div>
      <div style={{ maxHeight: '733px' }}>
        <table className="timeline-table">
          <tr></tr>
          <CardsContainer>
            <div className="table-title">
              <TextField
                type="text"
                size="normal"
                defaultValue={title}
                label="Título da linha do tempo (opcional)"
                onChange={(value) => changeTitleTimeline(value)}
              />
            </div>
            {data.map((t, index) => (
              <tr key={index}>
                <ConfigurationCard
                  focusId={`item${index}`}
                  addNewItem={() => addNewItem(index)}
                  dateInputValue={t.date}
                  titleInputValue={t.title}
                  descriptionInputValue={t.description}
                  dateType={dateType}
                  deleteItem={() => controlDeleteModal(index)}
                  changeColorItem={() => controlModalColor(index, t.color)}
                  changeValueItem={(value, name) => {
                    changeItemValue(index, value, name);
                  }}
                />
              </tr>
            ))}
          </CardsContainer>
        </table>
      </div>
    </Container>
  );
};

export default VerticalConfiguration;
