import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import logo from '../../assets/Logo-small.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Toolbar } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Content } from './styles';
import AvatarUser from '../AvatarUser';

const drawerWidth = 240;
const modeloToolbarFixo = true;

const useStyles = makeStyles((theme) => ({
  title: { flexGrow: 1 },
  root: {
    display: 'flex',
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 16,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(1),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 1,
    // padding: theme.spacing(3),
    // marginTop: theme.spacing(2),
  },
  paper: {
    width: '150px',
    height: '50px',
    margin: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuSelecionado: {
    color: '#3fced2',
  },
}));

export interface IMenu {
  name: string | undefined;
  icon: any | undefined;
  onClick: () => void;
  submenu: any[];
}

interface IProps {
  menu: IMenu[];
  menuAvatar: IMenu[];
  selectedMenu: string;
  children: React.ReactElement | React.FC;
}

const MenuLateral = ({ menu, selectedMenu, menuAvatar, children }: IProps): React.ReactElement => {
  const style = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(stateDrawer());
  const openDrawer = React.useCallback(() => setOpen(true), []);
  const closeDrawer = React.useCallback(() => setOpen(false), []);

  useEffect(() => {
    localStorage.setItem('drawerMenu', open ? 'open' : 'closed');
  }, [open]);

  function stateDrawer() {
    return localStorage.getItem('drawerMenu') == 'closed' ? false : true;
  }

  return (
    <div className={style.root}>
      <AppBar
        position="fixed"
        className={clsx(style.appBar, {
          [style.appBarShift]: open && !modeloToolbarFixo,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={open ? closeDrawer : openDrawer}
            className={style.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={style.title}>
            <img src={logo} />
          </Typography>
          <AvatarUser menu={menuAvatar} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(style.drawer, {
          [style.drawerOpen]: open,
          [style.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [style.drawerOpen]: open,
            [style.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={style.toolbar}>
          <IconButton onClick={closeDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
          {menu.map((item, index) => (
            <ListItem
              button
              className={selectedMenu == item.name ? style.menuSelecionado : ''}
              key={item.name}
              onClick={item.onClick}
            >
              <ListItemIcon className={selectedMenu == item.name ? style.menuSelecionado : ''}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Content>{children}</Content>
    </div>
  );
};

export default MenuLateral;
