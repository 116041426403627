/* eslint-disable prettier/prettier */
import api from './api';
import ComparativeTableImg from '../assets/tabela.png';
import TimeLineImg from '../assets/linhadotempo.png';
import QrCodeImg from '../assets/qrcode.png';
import DiagramImg from '../assets/graficolinha.png';
import FlowChartImg from '../assets/fluxograma.png';
import IA from '../assets/linguagemsimples.svg';
import Ementa from '../assets/Ementa.svg';
import Icones from '../assets/ConjuntoIcones.png';

const modelo = [
  {
    id: 'C363CE81-B5B4-4C3C-9F90-AD04F685B1E6',
    titulo: 'Tabela',
    descricao:
      'Utilizamos tabelas para organizar informações em linhas e colunas, permitindo compará-las, associá-las ou simplesmente ordená-las. São úteis para, por exemplo, comparar um caso concreto com um julgado, elencar débitos de verbas trabalhistas, demonstrar itens e os respectivos valores em uma ação de alimentos.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/tabela.png',
    imagemNewTemplate: ComparativeTableImg,
    link: '/tabela-comparativa',
    video: { videoId: 'e98P99QhrT8', titulo: null },
  },
  {
    id: '2C079D9D-53A2-4443-B522-FED242505E65', 
    titulo: 'Linha do tempo',
    descricao:
      'Quando se pretende retratar uma sequência cronológica de fatos e destacar datas e/ou horários, a linha do tempo é o recurso mais eficiente.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/linhadotempo.png',
    imagemNewTemplate: TimeLineImg,
    link: '/linha-do-tempo',
    video: { videoId: 'g2LVdhtZucQ', titulo: null },
  },
  {
    id: 'F9F8A07D-EFC0-4538-B8E7-96AC08055838',
    titulo: 'QR Code',
    descricao:
      'O QR Code é um portal para veicular informações externas ao documento por meio de uma URL (link) e ele pode conter, por exemplo, um vídeo, um site, uma reportagem, uma jurisprudência.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/qrcode.png',
    imagemNewTemplate: QrCodeImg,
    link: '/qrcode',
    video: { videoId: 'aqq5qQjPqlM', titulo: null },
  },
  {
    id: 'B0A63CD5-04A4-4FC4-80DE-2ABAA4CAAE98',
    titulo: 'Gráfico',
    descricao:
      'Utilizamos gráficos quando queremos comparar dados numéricos ou demonstrar uma evolução ou uma ' +
      'tendência. São úteis para, por exemplo, num ação revisional de alimentos, demonstrar a diferença ' +
      'entre o aumento do salário mínimo e a inflação registrada pelo IPCA em determinado período.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/graficolinha.png',
    imagemNewTemplate: DiagramImg,
    link: '/grafico',
    video: { videoId: 'AWLFrPdq-ok', titulo: null },
  },
  {
    id: 'DBA3148E-B977-4FEA-B3EF-59139F896385',
    titulo: 'Fluxograma',
    descricao:
      'Utilizamos fluxogramas para ilustrar sequencias de atos ou acontecimentos, fluxos de' +
      'informações, ou estruturas hierárquicas.' +
      '<strong>Hierárquico</strong>' +
      'Utilizamos o fluxograma hierárquico para retratar um cenário no qual existe uma supremacia do' +
      'elemento superior sobre o elemento inferior, como, por exemplo, uma árvore genealógica ou uma' +
      'estrutura hierárquica de uma empresa.' +
      '<strong>Sequencial</strong>' +
      'Utilizamos o fluxograma sequencial para retratar fatos nos quais não há supremacia entre eles,' +
      'apenas uma sequência, como, por exemplo, uma sucessão de atos processuais ou uma cadeia de' +
      'fornecimento de determinado produto.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/fluxograma.png',
    imagemNewTemplate: FlowChartImg,
    link: '/fluxograma',
    video: { videoId: '4QC5QLYHG9Q', titulo: null },
  },
  {
    id: '34BF82DC-81BB-4DD7-A8EE-FE5F917B510B',
    titulo: 'Texto Aprimorado',
    descricao: 'Este modelo ajuda você a simplificar a linguagem do seu texto e aplicar ao seu documento final.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/linguagemsimples.png',
    imagemNewTemplate: IA,
    link: '/ia-linguagem-simples',
    video: { videoId: 'd7cC_NeAdcs', titulo: null },
  },
  {
    id: '945BC3A7-62B5-44FC-8172-99B1764AA68D',
    titulo: 'Resumo Jurídico',
    descricao: 'Este modelo ajuda você a resumir, para rápida leitura, pequenos textos juridicos lidos a partir de arquivos PDF e DOCX.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/Ementa.png',
    imagemNewTemplate: Ementa,
    link: '/ementa',
    video: { videoId: 'd7cC_NeAdcs', titulo: null },
  },
  {
    id: '96ED4932-D083-4D3E-BCA6-9E596257CBA4',
    titulo: 'Banco de Ícones',
    descricao: 'Este modelo coloca em suas mãos uma extensa coleção de ícones que vc pode buscar através de palavras chave, desenhados a mão, por computador ou IA.',
    imagem: 'https://portalirisvl.blob.core.windows.net/modelos/ConjuntoIcones.png',
    imagemNewTemplate: Icones,
    link: '/icons',
    video: { videoId: 'J3NNT43XQG0', titulo: null },
  },
];

export const createComparativeTable = async (values) => {
  let body = {
    id: values.id,
    descricao: values.description,
    json: values.json,
    html: values.html,
    clienteTitularId: values.clienteTitularId,
    titulo: values.title,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloTabelaComparativa', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const tipoModelo = () => {
  return modelo;
}

export const createTimeLine = async (values) => {
  try {
    return await api.post('ModeloLinhaDoTempo', values);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createQrCode = async (values) => {
  let body = {
    id: values.id,
    clienteTitularId: values.clienteTitularId,
    url: values.url,
    descricao: values.descricao,
    titulo: values.titulo,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloQRCode', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createIconsLib = async (values) => {
  try {
    return await api.post('ModeloBibliotecaIcones', values);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createSampleLanguage = async (values) => {
  let body = {
    id: values.id,
    titulo: values.titulo,
    descricao: values.descricao,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    baseText: values.baseText,
    transformedText: values.transformedText,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloLinguagemSimples', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createEmenta = async (values) => {
  let body = {
    id: values.id,
    titulo: values.titulo,
    descricao: values.descricao,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    tipo: values.tipoModelo,
    metaFile: values.metaFile,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloEmenta', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createDiagram = async (values) => {
  let body = {
    id: values.id,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    descricao: values.description,
    titulo: values.title,
    posicao: values.position,
    formato: values.format === 'bar' ? 0 : 1,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloGrafico', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDiagram = async (values) => {
  let body = {
    id: values.id,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    descricao: values.description,
    titulo: values.title,
    posicao: values.position,
    formato: values.format === 'bar' ? 0 : 1,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.put('ModeloGrafico', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const createFlowChart = async (values) => {
  let body = {
    id: values.id,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    descricao: values.description,
    titulo: values.title,
    posicao: values.position,
    htmlImage: values.htmlImage,
  };

  try {
    return await api.post('ModeloFluxograma', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateFlowChart = async (values) => {
  let body = {
    id: values.id,
    clienteTitularId: values.clienteTitularId,
    html: values.html,
    json: values.json,
    descricao: values.description,
    titulo: values.title,
    posicao: values.position,
    htmlImage: values.htmlImage,
  };

  try {
    var response = await api.put('ModeloFluxograma', body);
    return response;
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateModel: AxiosResponse<unknown> = async (typeModel: string, body) => {
  try {
    return await api.put(typeModel, body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const getModelById: AxiosResponse<unknown> = async (typeModel: string, userId: string, modelId: string) => {
  try {
    let route = `client(${userId})/${typeModel}?$filter=id eq ${modelId}`;
    const response = await api.get(route);
    return response;
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const deleteModelById: AxiosResponse<unknown> = async (typeModel: string, clientId: string, id: string) => {
  let route;
  if (typeModel == 'TabelaComparativa') {
    route = `ModeloTabelaComparativa(${clientId},${id})`;
  } else {
    route = `${typeModel}(${clientId},${id})`;
  }
  try {
    const response = await api.delete(route);
    return response;
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const getModelsByUserId: AxiosResponse<unknown> = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string
) => {
  let route = `odata/clientModels?$count=true&$skip=${page * pageSize}&$top=${pageSize}&$orderby=dataCriacao desc&$filter=titularid eq ${userId} `;
  if (search) {
    route += `and contains(Descricao, '${search}') `;
  }
  try {
    const response = await api.get(route);
    return response;
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadFlowChart = async (id) => {
  try {
    return await api.put(`ModeloFluxograma/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadTimeLine = async (id) => {
  try {
    return await api.put(`ModeloLinhaDoTempo/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadQRCode = async (id) => {
  try {
    return await api.put(`ModeloQRCode/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadComparativeTable = async (id) => {
  try {
    return await api.put(`ModeloTabelaComparativa/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadDiagram = async (id) => {
  try {
    return await api.put(`ModeloGrafico/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadLinguagemSimples = async (id) => {
  try {
    return await api.put(`ModeloLinguagemSimples/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const updateDownloadEmenta = async (id) => {
  try {
    return await api.put(`ModeloEmenta/SetBaixado/(${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const getFileEmentaUrl = async (clientId, id) => {
  try {
    return await api.get(`ModeloEmenta/Metafile/getUri(${clientId},${id})`);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const askOpenAI = async (text) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(api.get(`OpenAI/AskToChat/?text=${text})`));
    } catch (error) {
      reject('Provavelmente estamos com alguma lentidão no sistema. (' + error.message + ')');
    }
  });
};

export const askTimeLineToOpenAI = async (clientId) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(api.get(`OpenAI/AskTimeLineToOpenAI/?clientId=${clientId}`));
    } catch (error) {
      reject('Provavelmente estamos com alguma lentidão no sistema. (' + error.message + ')');
    }
  });
};

export const upload = async (file: File, tipo: int, md: string) => {
  let formData = new FormData();
  let url = "";
  formData.append("file", file);
  try {
    if (tipo == 0) {
      url = "ModeloEmenta/UploadEmentaFileToText";
    } else if (tipo == 1) {
      url = "ModeloEmenta/UploadEmentaFileToTopic";
    } else if (tipo == 2) {
      url = "ModeloEmenta/UploadPrancheta";
    }
    return await api.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "ClientId": md,
      },
    })
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const getPrancheta = async (clientId: string) => {
  try {
    return await api.get(`ModeloEmenta/Prancheta(${clientId})`);
  } catch (error) {
    return error.message;
  }
};

export const deletePrancheta = async (clientId: string) => {
  try {
    return await api.delete(`ModeloEmenta/Prancheta(${clientId})`);
  } catch (error) {
    return error.message;
  }
};

export const verifySummary = async (CRC: string) => {
  try {
    return await api.get(`ModeloEmenta/MetaFile/${CRC}`);
  } catch (error) {
    return error.message;
  }
};

export const findIcons = async (term, color, shape, size) => {
  return new Promise((resolve, reject) => {
    try {
      const pTerm = term;
      const pColor = color != null && color != "" ? color : 'solid-black';
      const pShape = shape != null && shape != "" ? shape : 'outline';
      const pSize = size != null && size != "" ? size : '512';
      resolve(api.get(`Freepik/FindIcons/${pTerm}/${pColor}/${pShape}/${pSize}`));
    } catch (error) {
      reject('Erro ao acessar o banco de dados de ícones na internet. (' + error.message + ')');
    }
  });
};
