import React, { Fragment, useEffect } from 'react';
import logo from '../../assets/Logo-small.svg';
import { PersonOutline } from '@material-ui/icons';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import { Menu, MenuItem } from '@mui/material';
import { Fade } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Exit, Header, HeaderContent } from './styles';
import { useAuth } from '../../contexts/auth';
import MenuLateral from '../MenuLateral';
import Prancheta from '../Prancheta';

interface IMasterPageProps {
  children: React.ReactElement | React.FC;
}

const LayoutNew = true;

const MasterPage = ({ children }: IMasterPageProps): React.ReactElement => {
  const history = useHistory();
  const { user } = useAuth();
  const location = useLocation();
  const [openPrancheta, setOpenPrancheta] = React.useState(false);

  const selectedMenu = localStorage.getItem('menuSelected');

  useEffect(() => {
    if (location.pathname.includes('/usuarios-internos')) {
      persistMenu('Usuários');
    } else if (location.pathname.includes('/usuarios-externos')) {
      persistMenu('Usuários');
    } else if (location.pathname.includes('/planos')) {
      persistMenu('Plano');
    }
    if (location.pathname.includes('/modelos')) {
      persistMenu('Meus projetos');
    }
    if (location.pathname.includes('/novo-modelo')) {
      persistMenu('Novo projeto');
    }
  }, []);

  const persistMenu = (menuName: string) => {
    localStorage.setItem('menuSelected', menuName);
  };

  const menuAvatar = [
    {
      name: 'Meus dados',
      icon: <PersonOutline />,
      onClick: () => {
        persistMenu('Meus dados');
        history.push('/meus-dados');
      },
    },
    {
      name: <Exit>Sair</Exit>,
      icon: <LogoutIcon />,
      submenu: [],
      onClick: () => {
        history.push('/login');
      },
    },
  ];

  const menuBase = [
    {
      name: 'Página Inicial',
      icon: <HomeOutlinedIcon />,
      submenu: [],
      onClick: () => {
        persistMenu('Página Inicial');
        history.push('/');
      },
    },
    {
      name: 'Meus projetos',
      icon: <AutoAwesomeMosaicOutlinedIcon />,
      submenu: [],
      onClick: () => {
        persistMenu('Meus projetos');
        history.push('/meus-modelos');
      },
    },
    {
      name: 'Novo projeto',
      icon: <AddCircleOutlineOutlinedIcon />,
      submenu: [],
      onClick: () => {
        history.push('/novo-modelo');
        persistMenu('Novo projeto');
      },
    },
    {
      name: 'Adicionar usuários',
      icon: <PersonAddOutlinedIcon />,
      submenu: [],
      onClick: () => {
        persistMenu('Adicionar usuários');
        history.push('/usuarios-dependentes');
      },
    },
    {
      name: 'IA Iris',
      icon: <AutoFixHigh />,
      submenu: [],
      onClick: () => {
        setOpenPrancheta(true);
      },
    },
  ];

  const menu = [
    ...menuBase,
    {
      name: user?.username,
      icon: <PersonOutline />,
      onClick: () => {},
      submenu: menuAvatar,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<Array<null | (EventTarget & Element)>>([null]);

  const handleClickMenuOptions = (event: React.SyntheticEvent, index: number) => {
    const aux = anchorEl;
    aux[index] = event.currentTarget;
    setAnchorEl([...aux]);
  };
  const handleClose = (index: number) => {
    const aux = anchorEl;
    aux[index] = null;
    setAnchorEl([...aux]);
  };

  if (LayoutNew) {
    return (
      <>
        <MenuLateral menu={menuBase} selectedMenu={selectedMenu != null ? selectedMenu : ''} menuAvatar={menuAvatar}>
          {children}
        </MenuLateral>
        <Prancheta open={openPrancheta} closeModal={() => setOpenPrancheta(false)} />
      </>
    );
  } else {
    return (
      <>
        <Header elevation={1}>
          <HeaderContent>
            <div>
              <img src={logo} />
            </div>
            <div>
              {menu.map((item, index) => (
                <Fragment key={index}>
                  <p
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    className={selectedMenu === item.name ? 'menu-selected' : ''}
                    aria-expanded={Boolean(anchorEl[index]) ? 'true' : undefined}
                    onClick={(event) => {
                      handleClickMenuOptions(event, index);
                      item?.onClick();
                    }}
                  >
                    {item.icon}
                    <div>{item.name}</div>
                  </p>

                  {item.submenu.length > 0 ? (
                    <Menu
                      id="fade-menu"
                      anchorEl={anchorEl[index]}
                      open={Boolean(anchorEl[index])}
                      onClose={() => handleClose(index)}
                      TransitionComponent={Fade}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {item.submenu.map((subItem) => (
                        <MenuItem
                          key={subItem.name}
                          onClick={() => {
                            handleClose(index);
                            subItem.onClick();
                          }}
                        >
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  ) : null}
                </Fragment>
              ))}
            </div>
          </HeaderContent>
        </Header>
        <Content>{children}</Content>
        <Prancheta open={openPrancheta} closeModal={() => setOpenPrancheta(false)} />
      </>
    );
  }
};

export default MasterPage;
