import React, { useEffect, useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { deletePrancheta, getPrancheta, upload } from '../../services/models';
import { useAuth } from '../../contexts/auth';
import { useAlert } from '../../contexts/alert';
import { Box, Button, Modal, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import VisualizadorArquivo from './VisualizadorArquivo';
import ModalOptions from '../ModalOptions';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

const tamanho150mb = 150000000;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function removerPrimeiroCaractereSePonto(str: string) {
  if (str.charAt(0) === '.') {
    return str.substring(1);
  }
  return str;
}

const Prancheta = ({ open, closeModal }: IProps): React.ReactNode => {
  const { user } = useAuth();
  const { showAlertSuccess, showAlertError } = useAlert();

  const [loading, setLoading] = useState(false);

  const [openDropZone, setOpenDropZone] = useState(false);
  const handleOpenDropZone = () => setOpenDropZone(true);
  const handleCloseDropZone = () => setOpenDropZone(false);

  const [controlExcluirArquivo, setControlExcluirArquivo] = useState(false);
  const handleOpenExcluirArquivo = () => setControlExcluirArquivo(true);
  const handleCloseExcluirArquivo = () => setControlExcluirArquivo(false);

  const [preenchida, setPreenchida] = useState(false);

  const [temporaryPathName, setTemporaryPathName] = useState<string>('');
  const [temporarySuffix, setTemporarySuffix] = useState<string>('');

  useEffect(() => {
    if (open) {
      getPrancheta(user?.tokenData?.ClientId).then((response) => {
        if (response.status === 200) {
          if (response.data.isFilled === true) {
            setPreenchida(true);
            setTemporaryPathName(response.data.fileUri);
            setTemporarySuffix(removerPrimeiroCaractereSePonto(response.data.fileSuffix.toLowerCase()));
          } else {
            setPreenchida(false);
            handleOpenDropZone();
          }
        }
      });
    }
  }, [open]);

  const handleSubmit = async (files: File[]) => {
    setLoading(true);
    handleCloseDropZone();
    let response = await upload(files[0], 2, user?.tokenData?.ClientId);

    if (response.status === 200) {
      showAlertSuccess('Prancheta carregada com sucesso!');

      setPreenchida(true);
      setTemporaryPathName(response.data.fileUri);
      setTemporarySuffix(removerPrimeiroCaractereSePonto(response.data.fileSuffix.toLowerCase()));
    } else {
      showAlertError('Falha ao carregar a prancheta');
    }
    setLoading(false);
  };

  return (
    <>
      <BootstrapDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Anexe um Documento
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="3rem" />
              <Typography align="center" color="textPrimary" sx={{ ml: 2 }}>
                Carregando... Quase pronto!
              </Typography>
            </Box>
          )}

          {!loading && (
            <>
              <Typography gutterBottom>
                Clique no botão abaixo para carregar um arquivo na área de transferência. esse arquivo será utilizado
                pela nossa inteligência artificial.
              </Typography>

              {preenchida && <VisualizadorArquivo fileType={temporarySuffix} filePath={temporaryPathName} />}

              <Stack direction="row" spacing={2} mt={2}>
                <Button onClick={handleOpenDropZone} variant="contained" sx={{ mt: 2 }}>
                  Inserir / Atualizar
                </Button>

                {preenchida && (
                  <Button onClick={handleOpenExcluirArquivo} variant="outlined" color="error" sx={{ mt: 2 }}>
                    Excluir Arquivo
                  </Button>
                )}
              </Stack>
            </>
          )}

          <ModalOptions
            text={`Deseja realmente excluir o arquivo?`}
            open={controlExcluirArquivo}
            onCancel={handleCloseExcluirArquivo}
            onSave={() => {
              deletePrancheta(user?.tokenData?.ClientId).then((response) => {
                if (response.status === 204) {
                  setPreenchida(false);
                }

                handleCloseExcluirArquivo();
              });
            }}
          />

          <DropzoneDialog
            acceptedFiles={[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            filesLimit={1}
            cancelButtonText={'Cancelar'}
            submitButtonText={'Enviar'}
            maxWidth={'md'}
            dialogTitle={'Anexe um documento para trabalhar com IA'}
            dropzoneText={'Arraste e solte um arquivo aqui ou clique para selecionar (até 150 MB).'}
            maxFileSize={tamanho150mb}
            open={openDropZone}
            onClose={handleCloseDropZone}
            onSave={handleSubmit}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeModal}>
            Fechar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default Prancheta;
