import React from 'react';
import ConfigurationCard from '../../ConfigurationCard';
import { Container } from './styles';
import { DATETYPEINPUT } from '../../../../../interfaces/timeline';
import TextField from '../../../../../components/EditTableTemplateTextField';
import { Tooltip } from '@material-ui/core';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ModalRowColSettings from '../../../../../components/ModalRowColumnSettings';
import { useModal } from '../../../../../components/ModalRowColumnSettings/useModal';
import AddOutlineIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlineIcon from '@mui/icons-material/RemoveOutlined';
import ReorderIcon from '@mui/icons-material/Reorder';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
}

interface IProps {
  data: IItem[];
  title: string;
  addNewItem: () => void;
  increaseWidth: () => void;
  decreaseWidth: () => void;
  reorderItens: () => void;
  dateType: DATETYPEINPUT;
  changeItemValue: (index: number, value: string, name: string) => void;
  controlDeleteModal: (index: number) => void;
  controlDeleteAllModal: () => void;
  controlModalColor: (index: number | string, value: string) => void;
  changeTitleTimeline: (value: string) => void;
}

const HorizontalConfiguration = ({
  data,
  title,
  dateType,
  addNewItem,
  increaseWidth,
  decreaseWidth,
  reorderItens,
  changeItemValue,
  controlDeleteModal,
  controlDeleteAllModal,
  controlModalColor,
  changeTitleTimeline,
}: IProps) => {
  const splitToChunks = (arr) => {
    const chunkSize = 5;
    return arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  };

  const { isVisible, change } = useModal();
  const onCancel = () => change();
  const onSave = () => change();

  return (
    <Container>
      <div className="info-title" style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 10, color: '#3E4756', width: '500px', paddingLeft: '20px' }}>
          Informe os dados do modelo
        </p>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <React.Fragment>
              <Tooltip title="Criar linha do tempo com IA a partir do texto da prancheta">
                <AutoFixHigh style={{ cursor: 'pointer', color: '#3E4756' }} onClick={controlDeleteAllModal} />
              </Tooltip>
              <Tooltip title="Reordenar todos os elementos">
                <ReorderIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={reorderItens} />
              </Tooltip>
              <Tooltip title="Aumentar a largura de todos os elementos">
                <AddOutlineIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={increaseWidth} />
              </Tooltip>
              <Tooltip title="Diminuir a largura de todos os elementos">
                <RemoveOutlineIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={decreaseWidth} />
              </Tooltip>
              <Tooltip title="Alterar cor de todos os elementos">
                <ColorLensOutlinedIcon
                  style={{ cursor: 'pointer', color: '#3E4756' }}
                  onClick={() => controlModalColor('all')}
                />
              </Tooltip>
              <Tooltip title="Configurações">
                <SettingsOutlinedIcon style={{ cursor: 'pointer', display: 'none' }} onClick={() => change()} />
              </Tooltip>
              <ModalRowColSettings
                open={isVisible}
                height={'10'}
                width={'10'}
                onCancel={() => onCancel()}
                onSave={() => onSave()}
              />
            </React.Fragment>
          </div>
        </div>
      </div>
      <div className="table-title">
        <TextField
          type="text"
          size="normal"
          defaultValue={title}
          label="Título da linha do tempo (opcional)"
          onChange={(value) => changeTitleTimeline(value)}
        />
      </div>
      <div>
        <table className="timeline-table">
          {splitToChunks(data).map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                {item.map((card, indexCard) => (
                  <td key={indexCard}>
                    <ConfigurationCard
                      focusId={`item${indexCard + 5 * index}`}
                      addNewItem={() => addNewItem(indexCard + 5 * index)}
                      dateInputValue={card.date}
                      titleInputValue={card.title}
                      descriptionInputValue={card.description}
                      dateType={dateType}
                      deleteItem={() => controlDeleteModal(index * 5 + indexCard)}
                      changeColorItem={() => controlModalColor(index * 5 + indexCard, card.color)}
                      changeValueItem={(value, name) => changeItemValue(index * 5 + indexCard, value, name)}
                    />
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </table>
      </div>
    </Container>
  );
};

export default HorizontalConfiguration;
